<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container
    fluid
    class="pl-6 pr-6"
  >
    <v-tabs
      v-model="tab"
      class="pb-6"
    >
      <v-tab
        v-if="canAccess('VERSION:LIST')"
        id="iiotSystemInfoTabAvailableUpdates"
        data-cy="iiotSystemInfoTabAvailableUpdates"
        :key="availableUpdates"
        autofocus
        @click="tabChanged(0)"
      >
        <h4><v-icon
            id="iiotSystemInfoIconAvailableUpdates"
            data-cy="iiotSystemInfoIconAvailableUpdates"
            >
            {{
              tab === availableUpdates ?
              '$vuetify.icons.systemUpdateBlue':
              '$vuetify.icons.systemUpdate'
            }}
        </v-icon></h4>
      </v-tab>
      <v-tab
        v-if="canAccess('UI_USAGE_REPORT:VIEW')"
        id="iiotSystemInfoTabUsageReports"
        data-cy="iiotSystemInfoTabUsageReports"
        :key="usageReports"
        @click="tabChanged(1)"
      >
        <h4><v-icon
            id="iiotSystemInfoIconUsageReports"
            data-cy="iiotSystemInfoIconUsageReports"
        >
            {{
              tab === usageReports ?
              '$vuetify.icons.reportBlue':
              '$vuetify.icons.report'
            }}
        </v-icon></h4>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        :key="availableUpdates"
        v-if="canAccess('VERSION:LIST')"
      >
        <available-updates-list />
      </v-tab-item>
      <v-tab-item
        v-if="canAccess('UI_USAGE_REPORT:VIEW')"
        :key="usageReports"
      >
        <usage-reports
          :search="search"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import UsageReports from '@/components/usage-reports/UsageReports.vue';
import AvailableUpdatesList from '../components/available-updates/AvailableUpdatesList.vue';

export default {
  components: {
    UsageReports,
    AvailableUpdatesList,
  },
  props: ['search'],
  data: () => ({
    tab: null,
    availableUpdates: 0,
    usageReports: 1,
  }),
  beforeMount() {
    // eslint-disable-next-line no-restricted-globals
    this.tab = Number(new URLSearchParams(location.search).get('tab'));
  },
  mounted() {
    this.tabChanged(this.tab);
  },
  methods: {
    async tabChanged(tab) {
      await this.$router.push({
        name: 'System info',
        query: {
          tab,
        },
      }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">

#iiotSystemInfoIconAvailableUpdates  > svg {
  height: 30px;
  width: 30px;
  path {
    stroke-width: 1px;
  }
}

#iiotSystemInfoIconUsageReports  > svg {
  height: 30px;
  width: 30px;
  path {
    stroke-width: 1px;
  }
}

</style>
