<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="d-flex justify-start align-center">
    <v-chip
      color="white"
      @click="downloadCurrentMonthReport()"
      id="iiotCurrentMonthDownloadBtn"
      data-cy="iiotCurrentMonthDownloadBtn"
    >
      <span
        class="primary--text"
        id="iiotCurrentMonthDownloadBtnText"
        data-cy="iiotCurrentMonthDownloadBtnText"
      >
        {{ $t('usageReports.list.currentReportLabel') }}
      </span>
      <v-icon
        right
        size="24"
        id="iiotCurrentMonthDownloadBtnIcon"
        data-cy="iiotCurrentMonthDownloadBtnIcon"
      >
        mdi-file-download-outline
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
import Logger from '@/utils/logger';

export default {
  methods: {
    downloadCurrentMonthReport() {
      try {
        const currentDate = new Date();
        const month = new Intl.DateTimeFormat('en-US', {
          month: 'long',
        }).format(currentDate);
        this.$store.dispatch('usage-reports/get_single', { month, year: currentDate.getFullYear() });
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>

<style scoped>
  #iiotCurrentMonthDownloadBtnText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #iiotCurrentMonthDownloadBtnText:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: normal;
  }
</style>
